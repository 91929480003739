@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .blog h1 {
    @apply tw-font-bold lg:tw-text-3xl tw-text-xl;
  }

  .blog h2 {
    @apply tw-font-bold lg:tw-text-2xl tw-text-lg;
  }

  .blog h3 {
    @apply tw-font-bold lg:tw-text-xl tw-text-base;
  }

  .blog h4 {
    @apply tw-font-bold lg:tw-text-base tw-text-sm;
  }

  .blog h5 {
    @apply tw-font-bold tw-text-sm;
  }

  .blog h6 {
    @apply tw-font-bold tw-text-xs;
  }

  .blog strong {
    @apply tw-font-bold tw-block;
  }

  .blog ul {
    @apply tw-list-disc tw-px-5;
  }

  .blog ol {
    @apply tw-list-decimal tw-px-5;
  }

  .blog li {
    @apply tw-mb-1;
  }

  .mui-input input::placeholder {
    opacity: 0;
  }

  .mui-input input {
    width: 100%;
    outline: none;
    box-sizing: border-box;
  }

  .mui-input label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    pointer-events: none;
  }

  .mui-input input:focus + label,
  .mui-input input:not(:placeholder-shown) + label {
    top: 0;
    font-size: 0.8em;
    line-height: 0.8em;
    color: theme("colors.BluePrimary");
    background: #fff;
    padding: 7px 0;
  }

  .fill-svg-BluePrimary svg {
    fill: theme("colors.BluePrimary");
  }

  .gradient-button {
    @apply hover:tw-text-BluePrimary tw-transition-all tw-duration-500 hover:tw-cursor-pointer tw-bg-[length:200%_200%] tw-text-white tw-bg-GradientBlueLightBlue tw-animate-[getStartedAsBtnBg_15s_ease-in-out_infinite] tw-relative hover:tw-shadow-md hover:before:tw-w-[102%] before:tw-absolute before:tw-rounded-full before:tw-h-[105%] before:tw-bg-white before:tw-w-0 before:tw-top-[-1px] before:tw-left-[-1px] before:tw-transition-all tw-z-[1] before:tw-z-[-1] before:tw-duration-500;
  }

  @keyframes getStartedAsBtnBg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .light-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .light-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(211, 211, 211, 0.4);
    border-radius: 2px;
  }

  .light-scrollbar::-webkit-scrollbar {
    width: 6.9px;
  }

  .light-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(211, 211, 211, 0.4);
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 0px !important;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .no-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

  .no-scrollbar {
    scrollbar-width: none !important;
    scrollbar-color: transparent !important;
  }

  a {
    color: #4281fd;
  }
}

input {
  background-color: white;
}

* {
  color: black;
}

a {
  text-decoration: none !important;
}

body {
  overflow-x: hidden;
}

/*Custom Scrollbar Design*/
* {
  scrollbar-width: inherit !important;
  scrollbar-color: #4281fd #e0e0e0 !important;
}

body {
  overflow-x: hidden;
}

::selection {
  background-color: #4281fd;
  color: white;
}
